"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Header = /** @class */ (function () {
    function Header(props) {
        this.props = props;
    }
    Header.prototype.remove = function () {
        var headerElement = document.querySelector('[data-wayke-valuation-header]');
        if (headerElement) {
            headerElement.innerHTML = '';
        }
    };
    Header.prototype.render = function (back) {
        var headerElement = document.querySelector('[data-wayke-valuation-header]');
        if (headerElement) {
            headerElement.innerHTML = "\n        <div class=\"header\">\n          <div class=\"header-action\"></div>\n          <div class=\"header-logo-container\">\n            " + this.props.logo + "\n          </div>\n          <div class=\"header-action\">\n            <button class=\"header-action-btn\" title=\"St\u00E4ng\"><i class=\"icon-close no-margin\"></i></button>\n          </div>\n        </div>\n      ";
            var firstAction = headerElement.querySelectorAll('.header-action');
            if (firstAction.item(0)) {
                if (back) {
                    if (firstAction) {
                        firstAction.item(0).innerHTML = "\n            <button title=\"Tillbaka\" data-wayke-valuation-link=\"\">\n              <i class=\"icon-chevron-left m-r-half\"></i> Tillbaka\n            </button>\n          ";
                        var button = firstAction.item(0).querySelector('button');
                        if (button) {
                            button.addEventListener('click', back);
                        }
                    }
                }
                else {
                    firstAction.item(0).innerHTML = '';
                }
            }
            if (firstAction.item(1)) {
                var button = firstAction.item(1).querySelector('button');
                if (button) {
                    button.addEventListener('click', this.props.close);
                }
            }
        }
    };
    return Header;
}());
exports.default = Header;
