"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.sendRequest = exports.RequestError = void 0;
var RequestError = /** @class */ (function (_super) {
    __extends(RequestError, _super);
    function RequestError(message, status) {
        var _this = _super.call(this, message) || this;
        _this.status = status;
        return _this;
    }
    return RequestError;
}(Error));
exports.RequestError = RequestError;
function _fetch(options, callback, errorCallback) {
    var request = new XMLHttpRequest();
    request.open(options.method, options.url, true);
    request.onload = function () {
        if (this.status >= 200 && this.status < 400) {
            // Success!
            var data = JSON.parse(this.response);
            if (callback) {
                callback(data);
            }
        }
        else {
            if (errorCallback) {
                errorCallback(new RequestError(request.statusText, request.status));
            }
        }
    };
    request.onerror = function (err) {
        if (errorCallback) {
            errorCallback(err);
        }
    };
    if (options.method === 'POST') {
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
    }
    if (options === null || options === void 0 ? void 0 : options.headers) {
        Object.keys(options.headers).forEach(function (key) {
            var _a;
            var value = (_a = options.headers) === null || _a === void 0 ? void 0 : _a[key];
            if (value) {
                request.setRequestHeader(key, value);
            }
        });
    }
    var body = options.body
        ? JSON.stringify(options.body)
        : undefined;
    request.send(body);
}
function sendRequest(options) {
    return new Promise(function (resolve, reject) {
        _fetch(options, resolve, reject);
    });
}
exports.sendRequest = sendRequest;
