"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Timeline = /** @class */ (function () {
    function Timeline() {
    }
    Timeline.prototype.changeStage = function (stage) {
        var timelineElement = document.querySelector('#timeline-wrapper');
        if (timelineElement) {
            var liList = timelineElement.querySelectorAll('li');
            if (!liList.length) {
                this.render();
                liList = timelineElement.querySelectorAll('li');
            }
            liList.forEach(function (el, i) {
                if (i === stage - 1) {
                    el.classList.add('is-active');
                }
                else {
                    el.classList.remove('is-active');
                }
            });
        }
    };
    Timeline.prototype.remove = function () {
        var timelineElement = document.querySelector('#timeline-wrapper');
        if (timelineElement) {
            timelineElement.innerHTML = '';
        }
    };
    Timeline.prototype.render = function () {
        var timelineElement = document.querySelector('#timeline-wrapper');
        if (timelineElement) {
            timelineElement.innerHTML = "\n        <div data-wayke-valuation-timeline>\n            <div class=\"timeline\">\n                <ul class=\"timeline-list\">\n                    <li class=\"timeline-item is-active\">\n                        <div class=\"timeline-indicator\"></div>\n                        <div class=\"timeline-label\">1. Biluppgifter</div>\n                    </li>\n                    <li class=\"timeline-item\">\n                        <div class=\"timeline-indicator\"></div>\n                        <div class=\"timeline-label\">2. Skick</div>\n                    </li>\n                    <li class=\"timeline-item\">\n                        <div class=\"timeline-indicator\"></div>\n                        <div class=\"timeline-label\">3. V\u00E4rdering</div>\n                    </li>\n                </ul>\n            </div>\n        </div>\n      ";
        }
    };
    return Timeline;
}());
exports.default = Timeline;
