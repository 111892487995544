"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Stage5 = /** @class */ (function () {
    function Stage5() {
    }
    Stage5.prototype.render = function () {
        var element = document.querySelector('[data-wayke-valuation-page]');
        if (element) {
            element.innerHTML = "\n          <section class=\"page-section\">\n            <h2 class=\"h6\">Tack f\u00F6r din intresseanm\u00E4lan!</h2>\n            <div data-wayke-valuation-content=\"\">\n              <p>Vi kommer att kontakta dig inom 48 timmar f\u00F6r att l\u00E4mna bud p\u00E5 din bil.</p>\n            </div>\n          </section>\n      ";
        }
    };
    return Stage5;
}());
exports.default = Stage5;
