"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.objToKeyValue = exports.formatPrice = void 0;
var separateEveryThirdCharacter = function (value) {
    var characters = value.split('').reverse();
    var result = characters[0];
    for (var i = 1; i < characters.length; i += 1) {
        var shouldAddSeparator = i % 3 === 0;
        if (shouldAddSeparator) {
            result = " " + result;
        }
        result = characters[i] + result;
    }
    return result;
};
var formatPrice = function (value, decimalSymbol) {
    if (decimalSymbol === void 0) { decimalSymbol = ','; }
    if (value === null || value === undefined) {
        return null;
    }
    var roundedValue = Math.round(value);
    var stringValue = "" + roundedValue;
    var parts = stringValue.split('.');
    var valueBeforeDecimal = parts[0];
    var separated = separateEveryThirdCharacter(valueBeforeDecimal);
    var hasDecimal = parts.length > 1;
    return hasDecimal ? separated + decimalSymbol + parts[1] : separated;
};
exports.formatPrice = formatPrice;
var objToKeyValue = function (obj) {
    return Object.keys(obj).map(function (key) { return ({
        key: key,
        value: obj[key],
    }); });
};
exports.objToKeyValue = objToKeyValue;
