"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var regex_1 = require("./regex");
var fieldValidation = {
    requiredRegistrationNumber: function (s) { return regex_1.registrationNumberReg.test(s || ''); },
    requiredNumberGreaterOrEqualThanZero: function (s) {
        if (s === undefined || s === null) {
            return false;
        }
        var f = parseInt(s.toString(), 10);
        return f !== NaN && f >= 0;
    },
    requiredWhenToSell: function (s) { return ['1', '2', '3'].includes(s || ''); },
    requiredString: function (s) { return !!s; },
    optionalString: function (s) { return !s || !!s; },
    requiredEmail: function (s) { return regex_1.emailReg.test(s || ''); },
    requiredTrue: function (s) { return s === true; },
};
exports.default = fieldValidation;
